import { TableCell, TableRow } from "@mui/material";
import React, { VFC } from "react";

interface Props {
  rowId: number;
  rowHeader: string;
  values: any[];
}

const HistoryTableRow: VFC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell
        width="55"
        padding="none"
        size="small"
        component="th"
        scope="row"
        sx={{
          height: props.rowHeader ? "auto" : "1px",
        }}
      >
        {props.rowHeader || <span>&nbsp;</span>}
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          padding="none"
          size="small"
          align="center"
          key={`${props.rowHeader}-${props.rowId}-${i}`}
        >
          {typeof v === "string" ? v : v ? v.toLocaleString() : 0}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default HistoryTableRow;
