const CalcStockMonth = (
  sales: number[],
  stock: number[],
  seasonalityCode: number
): string[] => {
  if (!sales || !stock || sales.length === 0 || stock.length === 0) {
    return [];
  }

  const getAverage = (index: number, windowSize: number): number => {
    const start = Math.max(0, index - windowSize + 1);
    const end = index + 1;
    const sum = sales.slice(start, end).reduce((acc, val) => acc + val, 0);
    return sum / (end - start);
  };

  const windowSize = seasonalityCode === 3 ? 3 : seasonalityCode === 6 ? 6 : 12;

  return stock.map((stockValue, i) => {
    const average = getAverage(i, windowSize);
    const result = stockValue / (average > 0 ? average : 1);
    return (Math.round(result * 10) / 10).toFixed(1);
  });
};

export default CalcStockMonth;
