import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";

interface Props {
  rowId: number;
  rowHeader: string;
  values: any[];
  minimumStockMonth?: Carrier;
  isOnlySea?: boolean;
}

interface Carrier {
  sea: number;
}

const getMonthBgColor = (props: Props, cellValue: number, index: number) => {
  if (cellValue < 0) return "red";
  const { minimumStockMonth } = props;
  return minimumStockMonth && cellValue < minimumStockMonth.sea ? "orange" : "";
};

const StockMonthTableRow: FC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell padding="none" size="small" component="th" scope="row">
        {props.rowHeader}
      </TableCell>
      {props.values.map((v, i) => (
        <TableCell
          width={60}
          padding="none"
          size="small"
          align="center"
          key={`stk-${props.rowId}-${i}`}
          sx={{
            backgroundColor: getMonthBgColor(props, v, i),
          }}
        >
          {v}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default StockMonthTableRow;
