import React, { memo, VFC, useMemo } from "react";
import {
  Bar,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import CalcForecast from "../../../functions/CalcForecast";

interface Props {
  air: number;
  sea: number;
  detail: Paths.GetStockMovementDetails.Responses.$200["details"][0];
  monthHeaders: string[];
  isOnlySea: boolean;
}

type StockChartType = {
  month: string;
  sales: number;
  purchase?: number;
  additionalPurchase?: number;
  stock?: number;
  stockMonth?: number;
  stockHistory?: number;
  combinedStock?: number;
};

const StockChart: VFC<Props> = memo((props) => {
  const { monthHeaders, detail, isOnlySea } = props;
  const pastMonthHeaders = monthHeaders.slice(0, 12);
  const combinedMonthHeaders = pastMonthHeaders.concat(monthHeaders);
  const currentMonth = monthHeaders[0];
  const chartData = useMemo(() => {
    const ocfArray = [
      detail.ocf_forecast1 || 0,
      detail.ocf_forecast2 || 0,
      detail.ocf_forecast3 || 0,
      detail.ocf_forecast4 || 0,
      detail.ocf_forecast5 || 0,
    ];

    const res = CalcForecast({
      isOnlySea: isOnlySea,
      bo: detail.back_order || 0,
      mtdSales: detail.mtd_sales || 0,
      stock: detail.stock || 0,
      salesHistory: detail.sales,
      ltmAIR: detail.ltm_air,
      ltmSEA: detail.ltm_sea,
      msmSea: detail.msm_sea || 1,
      moq: detail.moq || 1,
      ocfForecast: ocfArray,
      isDiscon:
        (detail.alternate ?? "").includes("[To]") ||
        (detail.status ?? "").includes("D"),
      layer: detail.layer || 1,
      seasonalityCode: detail.seasonality_code || 12,
      suggestAIR: detail.suggest_air,
      suggestSEA: detail.suggest_sea,
      purchase: detail.purchase_forecast,
      sales: detail.sales_forecast,
    });

    const salesResult: StockChartType[] = detail.sales?.map((s, i) => ({
      month: combinedMonthHeaders[i],
      sales: s,
      stockHistory: detail.stock_history[i],
      minimumStockMonth: detail.msm_sea || 0,
    }));

    const forecast: StockChartType[] = detail.sales_forecast?.map((s, i) => ({
      month: combinedMonthHeaders[i + 12],
      sales: s,
      purchase: detail.purchase_forecast[i],
      additionalPurchase:
        i === detail.ltm_air
          ? props.air
          : i === detail.ltm_sea
          ? props.sea
          : i > detail.ltm_sea
          ? res.additionalPurchase[i]
          : undefined,
      stock: res.stock[i],
      stockMonth: res.month[i],
    }));

    const combinedData = salesResult.concat(forecast);

    combinedData.forEach((data, i) => {
      if (i < 12) {
        data.combinedStock = (data.stockHistory || 0) + (data.stock || 0);
      } else {
        data.combinedStock = data.stock;
      }
    });

    return combinedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.air, props.sea, props.detail]);
  return (
    <StockChartPresentation
      data={chartData}
      minimumStockMonth={detail.msm_sea || 0}
      currentMonth={currentMonth}
      dynamicMinimumBalance={detail.dmb_sea || 0}
    />
  );
});

interface PresentationProps {
  data: StockChartType[];
  minimumStockMonth: number;
  currentMonth: string;
  dynamicMinimumBalance: number;
}

const renderCustomTick = (props: any) => {
  const { x, y, payload, index } = props;

  const isHighlighted = index === 12;

  return (
    <text
      x={x}
      y={y + 15}
      fontSize={12}
      fontWeight={isHighlighted ? "bold" : "normal"}
      textAnchor="middle"
    >
      {payload.value}
    </text>
  );
};

const StockChartPresentation: VFC<PresentationProps> = (props) => {
  const stockData = props.data?.map((props) => props.stock ?? 0);
  const dynamicMinimumBalance = props.dynamicMinimumBalance;
  const minStock = Math.round(Math.min(...stockData) * 1.1);
  const maxStock = Math.round(Math.max(...stockData) * 1.1);
  const minDomain =
    minStock < dynamicMinimumBalance ? minStock : dynamicMinimumBalance;
  const maxDomain =
    maxStock > dynamicMinimumBalance ? maxStock : dynamicMinimumBalance;

  return (
    <>
      <ResponsiveContainer width="100%" minHeight={250} height={250}>
        <ComposedChart
          data={props.data}
          margin={{
            top: 5,
            right: 0,
            left: -15,
            bottom: 10,
          }}
          syncId="stock"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            xAxisId={0}
            fontSize={12}
            tick={renderCustomTick}
            interval={0}
          />
          <XAxis dataKey="month" xAxisId={1} hide />
          <YAxis yAxisId={0} domain={[minDomain, maxDomain]} fontSize={12} />
          <Tooltip
            formatter={(value: number) =>
              new Intl.NumberFormat(undefined, {
                maximumFractionDigits: 0,
              }).format(+value)
            }
          />
          <Legend />
          <Bar
            xAxisId={0}
            name="Stock"
            dataKey="combinedStock"
            barSize={25}
            fill="#0930B0"
            opacity="0.8"
          />
          <Bar
            xAxisId={1}
            stackId="s"
            name="Purchase"
            dataKey="purchase"
            barSize={25}
            fill="#94BBD6"
          />
          <Bar
            xAxisId={1}
            stackId="s"
            name="Additional Purchase"
            dataKey="additionalPurchase"
            barSize={25}
            fill="#ED911A"
            opacity="0.4"
          />
          <Line
            xAxisId={0}
            name="Sales"
            type="linear"
            dataKey="sales"
            stroke="#E0385D"
            strokeWidth={3}
            activeDot={{ r: 9 }}
          />
          <ReferenceLine
            yAxisId={0}
            y={props.dynamicMinimumBalance}
            label={{
              position: "left",
              value: "DMB",
              fontSize: "small",
              style: { fill: "#09854A" },
            }}
            strokeDasharray="3 3"
            stroke="#09854A"
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default StockChart;
