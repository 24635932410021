import { TableCell, TableRow } from "@mui/material";
import React, { VFC, useState, useEffect } from "react";

interface Props {
  vendor: string;
  item: string;
  rowId: number;
  rowHeader: string;
  values: number[];
  editable: boolean;
  selectedCells: string[];
  setSelectedCells: React.Dispatch<React.SetStateAction<string[]>>;
  cellValues: { [key: string]: number };
  setCellValues: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  handleCommit: (
    id: string,
    value: number,
    itemNo: string,
    vendor: string
  ) => void;
  ltm_air?: number;
  ltm_sea?: number;
  isOnlySea?: boolean;
}

const ForecastTableRow: VFC<Props> = ({
  vendor,
  item,
  rowId,
  rowHeader,
  values,
  editable,
  selectedCells,
  setSelectedCells,
  cellValues,
  setCellValues,
  handleCommit,
  ltm_air,
  ltm_sea,
  isOnlySea,
}) => {
  const [prevSelectedRow, setPrevSelectedRow] = useState(-1);

  const handleCellClick = (
    event: React.MouseEvent,
    rowId: number,
    cellId: string,
    value: number
  ) => {
    if (prevSelectedRow !== rowId) {
      setSelectedCells([]); // 全ての選択を解除
      setPrevSelectedRow(rowId); // 前回選択された行を更新
    }

    if (event.metaKey) {
      setSelectedCells((prev) => {
        if (prev.includes(cellId)) {
          return prev.filter((id) => id !== cellId);
        } else {
          return [...prev, cellId];
        }
      });
      console.log(`Cell ${cellId} selected with value: ${value}`);
    } else {
      setSelectedCells([]); // 全ての選択を解除
      console.log("All selections cleared");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation(); // イベントのバブリングを防止
      setSelectedCells([]); // 全ての選択を解除
      console.log("All selections cleared from outside click");
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation(); // イベントのバブリングを防止
      if (event.key === "Escape") {
        setSelectedCells([]); // 全ての選択を解除
        console.log("All selections cleared by pressing Escape key");
      }
    };

    const targetTableElement = document.getElementById(
      "datagrid-forecast-table"
    );

    if (targetTableElement) {
      targetTableElement.addEventListener("click", handleClickOutside);
      targetTableElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (targetTableElement) {
        targetTableElement.removeEventListener("click", handleClickOutside);
        targetTableElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const newValue = parseFloat(event.target.value.replace(/,/g, ""));

    setCellValues((prev) => {
      const updatedValues = { ...prev };
      selectedCells.forEach((cellId) => {
        updatedValues[cellId] = newValue;
      });
      return updatedValues;
    });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    id: string,
    value: number,
    itemNo: string,
    vendor: string
  ) => {
    if (event.key === "Enter") {
      handleCommit(id, value, itemNo, vendor);
      setSelectedCells([]);
    }
  };

  return (
    <TableRow>
      <TableCell
        width="55"
        padding="none"
        size="small"
        component="th"
        scope="row"
      >
        {rowHeader}
      </TableCell>
      {values.map((v, i) => {
        const cellId = `${rowHeader.toLowerCase()}-${i + 1}-${rowId}`; // iは0から始まるため+1
        const cellValue =
          cellValues[cellId] !== undefined ? cellValues[cellId] : v;

        let editableCell = editable;
        if (rowHeader === "Additional") {
          editableCell =
            i === ltm_air ||
            i === ltm_sea ||
            (i > (ltm_sea ?? 0) && i <= (ltm_sea ?? 0) + 5);
        }

        return (
          <TableCell
            padding="none"
            size="small"
            align="center"
            id={`id-${cellId}`}
            key={cellId}
            sx={{
              bgcolor: selectedCells.includes(cellId)
                ? "rgba(170,220,310,0.3)"
                : i === ltm_air && !isOnlySea
                ? "rgba(0,0,200,0.1)"
                : i === ltm_sea
                ? "rgba(0,200,0,0.12)"
                : ltm_sea && i > ltm_sea && i <= ltm_sea + 5
                ? "rgba(211,211,211,0.3)"
                : "",
              fontWeight:
                (isOnlySea && i === ltm_sea) ||
                (!isOnlySea && (i === ltm_air || i === ltm_sea))
                  ? "bold"
                  : "",
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleCellClick(event, rowId, cellId, cellValue);
            }}
            onKeyDown={(event) =>
              handleKeyDown(event, cellId, cellValue, item, vendor)
            }
          >
            {editableCell ? (
              <input
                id={cellId}
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  textAlign: "center",
                  backgroundColor: "inherit",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #ddd",
                  textDecoration: "underline",
                  cursor: selectedCells.includes(cellId) ? "text" : "default",
                }}
                className="hide-spin"
                type="number"
                value={cellValue}
                onChange={(event) => handleValueChange(event, cellId)}
              />
            ) : (
              cellValue.toLocaleString()
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ForecastTableRow;
